function bgVideo() {
	if ($("section.about-video").length) {
		$(".about-video video-js")
			.addClass("bg-video")
			.attr("id", "aboutVideo");
		$(".about-view-more").on("click", function(e) {
			e.preventDefault();
			const element = $(this).attr("href");
			$("html, body").animate(
				{
					scrollTop: $(element).offset().top
				},
				500,
				"linear"
			);
		});
	}
}
export default bgVideo;
