/**
 * Javascript for Culture Content Hub Video Play
 **/

function CultureContentHub() {
	this.selectors = {
		galleryClick: ".culture-content-hub .gallery-shows .ess-show",
		gallery: ".ess-show",
		slide: ".swiper-slide",
		activeSlide: ".culture-content-hub .gallery-shows .swiper-slide.active",
		$loading: $(".culture-content-hub .loading"),
		cardLinks: ".culture-content-hub .essence-topic a",
		$hero: $(".culture-content-hub .video-lp-hero"),
		$heroImage: $(".culture-content-hub .video-lp-hero__bg"),
		$heroSubtitle: $(".culture-content-hub .video-lp-hero__subheading"),
		$heroTitle: $(".culture-content-hub .video-lp-hero__heading"),
		$heroDesc: $(".culture-content-hub .video-lp-hero__description"),
		$heroVideo: $(".culture-content-hub .video-lp-hero__video"),
		title: $(".pubstack-section-heading"),
		cardClick: ".culture-content-hub .essence-topic",
		card: ".essence-topic",
		activeCard: ".essence-topic.active",
		cardImage: ".essence-topic__featured-image img",
		cardSubtitle: ".essence-topic__category-list",
		cardTitle: ".essence-topic__title",
		cardDesc: ".essence-topic__description",
		cardVideo: ".essence-topic__link",
		$topicsWapper: $(".video_landing-content .essence-topics__inner"),
	};

	this.init = () => {
		this.register();
	};

	this.register = () => {
		$(document).on("click", this.selectors.galleryClick, this.onGalleryClick);
		$(document).on("click", this.selectors.cardClick, this.onCardClick);
	};

	/* Toggle the playlist videos on the playlist gallery items click */
	this.onGalleryClick = (e) => {
		const clickedGallery = $(e.target).closest(this.selectors.gallery);

		// Get the new playlist.
		const playlist = clickedGallery.data("id");

		// Get the old playlist.
		const oldPlaylist = this.selectors.$topicsWapper.data("current");

		// Store the new playlist.
		this.selectors.$topicsWapper.data("current", playlist);

		// Check if the user click the slide that is currently playing.
		if (oldPlaylist === playlist) {
			return;
		}

		// Toggle Active class.
		$(this.selectors.activeSlide).removeClass("active");
		$(e.target).closest(this.selectors.slide).addClass("active");

		// Move the old playlist videos to the hidden block.
		$(`#hidden-videos-${oldPlaylist}`).append(
			this.selectors.$topicsWapper.children()
		);

		// Get the hidden block for the new playlist.
		const $videos = $(`#hidden-videos-${playlist}`);

		// Check if videos fetched already.
		if ($videos.html()) {
			this.selectors.$topicsWapper.html($videos.children());
		} else {
			// Fetch videos if empty.
			$.ajax({
				url: `${pubstack.ajaxUrl}?action=get_videos_by_playlist&playlist=${playlist}`,
				beforeSend: () => this.selectors.$loading.removeClass("hidden"),
			})
				.done((response) => this.selectors.$topicsWapper.html(response))
				.always(() => this.selectors.$loading.addClass("hidden"));
		}
	};

	/* Play the video at the hero player on video card click */
	this.onCardClick = (e) => {
		// Get details.
		const $card = $(e.target).closest(this.selectors.card);
		const cardImage = $card.find(this.selectors.cardImage).attr("src");
		const cardSubtitle = $card.find(this.selectors.cardSubtitle).text();
		const cardTitle = $card.find(this.selectors.cardTitle).text();
		const cardDesc = $card.find(this.selectors.cardDesc).text();
		const videoId = $card.data("id");

		// Toggle Active class.
		$(this.selectors.activeCard).removeClass("active");
		$card.addClass("active");

		// Scroll to top
		$("html, body").animate({ scrollTop: 0 }, 500);

		// Replace the hero details.
		this.selectors.$heroImage.attr(
			"style",
			`background-image: url(${cardImage});`
		);
		this.selectors.$heroSubtitle.text(cardSubtitle);
		this.selectors.$heroTitle.text(cardTitle);
		this.selectors.$heroDesc.text(cardDesc);

		// Replace the video player.
		this.selectors.$heroVideo.empty();
		this.selectors.$heroVideo.html(
			`<div data-source="2" id="jw-video-box-${videoId}" class="ess-embed-content jw-video-box loaded observed played" data-video="${videoId}"><div id='jw-video-${videoId}'></div></div>`
		);
		window[`playerInstance_${videoId}`] = jwplayer(`jw-video-${videoId}`).setup(
			{
				playlist: `https://cdn.jwplayer.com/v2/media/${videoId}`,
				stretching: "exactfit",
				autostart: 1,
				repeat: true,
				mute: true,
				aspectratio: "16:9",
				sharing: { sites: ["facebook", "twitter", "email", "linkedin"] },
				cast: {},
				floating: { dismissible: true },
				autoPause: { viewability: false },
				displaytitle: true,
				displaydescription: true,
				controls: true,
			}
		);

		// Load the Video.
		$(window).trigger("jw:loadplayers");

		// Play the video if it was already playing.
		if (this.selectors.$hero.hasClass("is-active")) {
			window[`playerInstance_${videoId}`].play();
		}
	};
}

export default new CultureContentHub();
