/**
 * Video Landing page Hero
 */
function VideoHero() {
	this.hero = $(".video_landing .video-lp-hero");
	this.content = this.hero.find(".video-lp-hero__content");
	this.videoContainer = this.hero.find(".vjs-pip-container");
	this.video = this.hero.find(".video-lp-hero__video");
	this.resizeTimer;

	this.init = () => {
		if (!this.hero.length) {
			return;
		}

		this.register();
		this.calcHeroHeight();
		setTimeout(() => this.maybePlay(), 200);
	};

	this.register = () => {
		this.content.on("click", this.play);

		$(window).on("resize", () => {
			clearTimeout(this.resizeTimer);
			this.resizeTimer = setTimeout(() => this.calcHeroHeight(), 50);
		});
	};

	this.maybePlay = () => {
		if (this.hero.hasClass("autoplay")) {
			this.play();
		}
	};

	this.play = () => {
		this.hero.addClass("is-active");
		this.video.fadeIn();
		const videoId = this.video.find(".jw-video-box").data("video");
		window[`playerInstance_${videoId}`].play();
	};

	/**
	 * ------- Video Landing Page -------
	 * Handle hero video width and height
	 * ----------------------------------
	 *
	 *
	 * Aspect Ratio: 		16:9 ( 56.25% )
	 * Available height: 	<viewport height> - <top navbar height>
	 *
	 *
	 * IF available-height < width*56.25% THEN 	height available height
	 * 											width auto/centered
	 * IF available-height > width*56.25% THEN 	width 100%
	 * 											heigth 56.25%
	 * 											min-height 350px for mobile portrait, shift up the height to 56.25% on video play
	 *
	 */
	this.calcHeroHeight = () => {
		const windowWidth = window.innerWidth,
			windowHeight = window.innerHeight,
			standardHeight = (windowWidth * 9) / 16,
			headerHeight = $("#masthead").outerHeight(),
			availableHeight = windowHeight - headerHeight;

		let applicableHeight;

		if (availableHeight > standardHeight) {
			applicableHeight = standardHeight;
			this.video.css({ width: "100%", left: 0 });
		} else {
			applicableHeight = availableHeight;
			this.video.css({
				width: (applicableHeight / 9) * 16,
				left: (windowWidth - (applicableHeight / 9) * 16) / 2,
			}); // centering
		}

		this.hero.css("height", applicableHeight);
		if (this.hero.hasClass("is-active")) {
			this.hero.css("padding-top", applicableHeight);
		}
		this.videoContainer.css({
			"padding-top": applicableHeight,
			height: applicableHeight,
		});
	};
}

export default new VideoHero();
