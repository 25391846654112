import colorbox from "jquery-colorbox";

function galleryOverlay() {
	if ($(".wp-block-gallery").length > 0) {
		var galGroup = 1;
		$(".wp-block-gallery").each(function() {
			// Add lightbox for images
			$(this)
				.find(".blocks-gallery-item")
				.colorbox({
					title: function() {
						return null;
					},
					maxWidth: "90%",
					maxHeight: "85%",
					fixed: true,
					className: "ess_lightbox",
					rel: "gallery" + galGroup,
					href: function() {
						return $(this)
							.find("img")
							.attr("src");
					},
					onComplete: function() {
						$(".cboxPhoto")
							.attr(
								"alt",
								$(this)
									.find("img")
									.attr("alt")
							)
							.attr(
								"title",
								$(this)
									.find("img")
									.attr("title")
							);
					}
				});
			galGroup++;
		});
		const contentEl = document.querySelector(".site");
		$(document).bind("cbox_open", function() {
			contentEl.style.overflow = "hidden";
			contentEl.style.height = "0";

			$("#colorbox").swipe({
				//Generic swipe handler for all directions
				swipeLeft: function(event, direction, distance, duration, fingerCount) {
					$.colorbox.next();
				},
				swipeRight: function(
					event,
					direction,
					distance,
					duration,
					fingerCount
				) {
					$.colorbox.prev();
				},
				//Default is 75px, set to 0 for demo so any distance triggers swipe
				threshold: 0
			});
		});
		$(document).bind("cbox_closed", function() {
			contentEl.style.overflow = "initial";
			contentEl.style.height = "auto";
		});
	}
}

export default galleryOverlay;
